import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Layout, PageContentWrapper, SEO } from '@components';
import { Box } from '@core';
import { CheckoutNavBar } from '@containers';
import { DOMAIN, META_IMAGE } from '@shared/constants/constants';

import { TermsPageTemplate } from './template/terms-page-template';

const TermsPage = ({ data, location }) => {
  const post = data.markdownRemark;
  const isForMobile = post.frontmatter.isForMobile;

  return (
    <Layout
      location={location}
      navProps={isForMobile ? { isHidden: true } : {}}
      footerProps={isForMobile ? { isHidden: true } : {}}
    >
      {post.frontmatter.canonical?.trim() && (
        <Helmet>
          <link
            rel="canonical"
            href={`${DOMAIN.mainUrl}${post.frontmatter.canonical}`}
            data-react-helmet="true"
          />
        </Helmet>
      )}
      {!post.frontmatter.hasSEOFeaturesEnabled && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <SEO {...post.frontmatter?.metaData} image={META_IMAGE} />
      {isForMobile && (
        <Box position="relative" maxWidth={1200} m="auto" zIndex={3}>
          <CheckoutNavBar isLogoClickable={false} isCurrencySelectShown={false} />
        </Box>
      )}
      <PageContentWrapper py={{ _: 30, lg: 120 }} px={{ _: 20, lg: 120 }}>
        <TermsPageTemplate
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          content={post.rawMarkdownBody}
        />
      </PageContentWrapper>
    </Layout>
  );
};

export default TermsPage;

export const termsPageQuery = graphql`
  query AboutPage($id: String!, $language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      rawMarkdownBody
      frontmatter {
        title
        date
        metaData {
          title
          description
        }
        canonical
        hasSEOFeaturesEnabled
        isForMobile
      }
    }
  }
`;
