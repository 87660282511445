import React from 'react';
import PropTypes from 'prop-types';

import { BodyContent, Heading } from '@templates/shared/components';
import { BodyMarkdownStyleWrapper } from '@shared/markdown-helpers';

export const TermsPageTemplate = ({ title, date, content }) => (
  <BodyMarkdownStyleWrapper>
    <Heading title={title} date={date} />
    <BodyContent content={content} />
  </BodyMarkdownStyleWrapper>
);

TermsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};
