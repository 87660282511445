import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { BlackFridayBlogBanner, ImageCore, Text } from '@components';
import { linkTargetPlugin } from '@shared/markdown-helpers';
import { Link, Table } from '@shared/markdown-components';

const ListItem = styled.li`
  font-family: inherit;
  font-weight: 500;
  line-height: 2.19;
`;

const UnorderedList = styled.ul`
  font-family: inherit;
  font-weight: 500;
  list-style: unset;
  padding-inline-start: 40px;
`;

const Strong = styled.strong`
  font-family: inherit;
  font-weight: 600;

  a {
    font-weight: 600;
  }
`;

const Code = styled.code`
  font-family: inherit;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.danger};
  background-color: ${({ theme }) => theme.colors.white_120};
`;

const Iframe = styled.iframe`
  width: 100%;

  @media (max-width: 720px) {
    &[height='700'] {
      height: calc(100vw - 40px);
    }
  }

  & + div {
    width: 90% !important;
  }
`;

const BodyContent = ({ content, isWide }) => (
  <ReactMarkdown
    components={{
      p: ({ node, className, ...props }) => (
        <Text.Body2
          color="#3b3b4d"
          lineHeight={2.19}
          my="1rem"
          className={className}
          // Adding text-align: center css rule to all paragraphs that contains img, so all images will be centered.
          textAlign={
            node.children.some(
              (child) =>
                child.tagName === 'img' ||
                (child.tagName === 'a' &&
                  child.children?.some((deeperChild) => deeperChild.tagName === 'img'))
            )
              ? 'center'
              : null
          }
          {...props}
        />
      ),
      h1: ({ node, ...props }) => (
        <Text.Heading1 color="#3b3b4d" fontSize={30} my={24} {...props} />
      ),
      h2: ({ node, ...props }) => (
        <Text.Heading2 color="#3b3b4d" fontSize={24} my={22} {...props} />
      ),
      h3: ({ node, ...props }) => (
        <Text.Subheading2 as="h3" color="#3b3b4d" fontSize={18} my={22} {...props} />
      ),
      h4: ({ node, ...props }) => <Text.Body2Strong as="h4" color="#3b3b4d" {...props} />,
      a: ({ node, href, ...props }) =>
        href ? (
          <Link link={href} {...props} removeAtlasDomain={false} />
        ) : (
          <a children={props.children} {...props} />
        ), // render simple <a> tag for edge cases when no href exists, e.g. <a id="blockchain"></a> in markdown
      li: ({ node, ...props }) => <ListItem {...props} />,
      ul: ({ node, ...props }) => <UnorderedList {...props} />,
      strong: ({ node, ...props }) => <Strong {...props} />,
      img: ({ node, ...props }) =>
        props.className === 'gatsby-resp-image-image' ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img {...props} />
        ) : (
          <ImageCore my={46} {...props} />
        ),
      table: ({ node, ...props }) => <Table {...props} />,
      code: ({ node, ...props }) => <Code {...props} />,
      iframe: ({ node, ...props }) => <Iframe {...props} />,
      div: ({ node, id, ...props }) =>
        id === 'CAMPAIGN_BANNER' ? <BlackFridayBlogBanner /> : <div id={id} {...props} />,
    }}
    remarkPlugins={[remarkGfm]} // remarkGfm plugin handles advanced markdown, e.g tables
    rehypePlugins={[rehypeRaw, linkTargetPlugin]} // rehypeRaw plugin needed to support html inside markdown (or handle html content when it's received instead of markdown)
  >
    {content}
  </ReactMarkdown>
);

export default BodyContent;
