import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

const Title = styled.h1`
  font-size: 30px;
  margin: 24px 0;
  font-weight: 700;
`;

const InfoContainer = styled.div`
  font-weight: 500;
  margin: 18px 0;
  padding-bottom: 10px;
`;

const Heading = ({ title, author, date }) => (
  <React.Fragment>
    <Title>{title}</Title>
    {author ? (
      <InfoContainer>
        {author} | {date && format(new Date(date), 'LLLL dd, yyyy')}
      </InfoContainer>
    ) : (
      <InfoContainer>
        Effective Date : {date && format(new Date(date), 'LLLL dd, yyyy')}
      </InfoContainer>
    )}
  </React.Fragment>
);

export default Heading;
